import * as request from '@/libs/utils/request'
import Config from '@/libs/config'
// 获取消息中心入口,历史消息
export function getMessageList({limit,is_page,type,page}) {
    return request.get({
        url: "/api/"+ Config.apiversion +"/user/message/list?type="+type + '&limit=' + limit + '&is_page=' + is_page + '&page=' + page
    })
}
// 获取消息详情
export function getMessageDetail(id) {
    return request.get({
        url: "/api/"+ Config.apiversion +"/user/message/detail?id="+id
    })
}
// 标记所有消息为已读
export function setMessageRead() {
    return request.get({
        url: "/api/"+ Config.apiversion +"/user/message/setRead"
    })
}